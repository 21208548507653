@font-face {
  font-family: "Fish&Chips";
  src: url("./fonts/fish&chips/fish&chips-Regular.eot") format("opentype"),
    url("./fonts/fish&chips/fish&chips-Regular.otf") format("truetype"),
    url("./fonts/fish&chips/fish&chips-Regular.woff") format("woff"),
    url("./fonts/fish&chips/fish&chips-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Fish&Chips";
  src: url("./fonts/fish&chips/fish&chips-bold.eot") format("opentype"),
    url("./fonts/fish&chips/fish&chips-bold.otf") format("truetype"),
    url("./fonts/fish&chips/fish&chips-bold.woff") format("woff"),
    url("./fonts/fish&chips/fish&chips-bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Fish&Chips";
  src: url("./fonts/fish&chips/fish&chips-italic.eot") format("opentype"),
    url("./fonts/fish&chips/fish&chips-italic.otf") format("truetype"),
    url("./fonts/fish&chips/fish&chips-italic.woff") format("woff"),
    url("./fonts/fish&chips/fish&chips-italic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Fish&Chips";
  src: url("./fonts/fish&chips/fish&chips-bolditalic.eot") format("opentype"),
    url("./fonts/fish&chips/fish&chips-bolditalic.otf") format("truetype"),
    url("./fonts/fish&chips/fish&chips-bolditalic.woff") format("woff"),
    url("./fonts/fish&chips/fish&chips-bolditalic.woff2") format("woff2");
  font-weight: bold;
  font-style: italic;
}

.FishNChips {
  font-family: "Fish&Chips", sans-serif;
}

.App {
  text-align: center;
}

.Nav-header {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-header {
  /*background-color: #282c34;*/
  min-height: 20vh;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 28px 16px 0;
  float: left;
}

.image {
  height: 31px;
  float: left;
}

.site-layout-background {
  background: #fff;
}

.quantity {
  margin-bottom: 8px;
}

.quantity .ant-avatar {
  margin-right: 8px;
}

@media print {
  .Nav-header {
    display: none;
  }
  .Nav-footer {
    display: none;
  }
  body * {
    visibility: hidden;
  }
  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.winter.ant-divider-horizontal.ant-divider-with-text::before,
.winter.ant-divider-horizontal.ant-divider-with-text::after {
  border-top: 1px solid #4e79a7;
}
.winter > .ant-divider-inner-text {
  color: #4e79a7;
  background-color: #a0cbe8;
}

.spring.ant-divider-horizontal.ant-divider-with-text::before,
.spring.ant-divider-horizontal.ant-divider-with-text::after {
  border-top: 1px solid #59a14f;
}
.spring > .ant-divider-inner-text {
  color: #59a14f;
  background-color: #8cd17d;
}

.summer.ant-divider-horizontal.ant-divider-with-text::before,
.summer.ant-divider-horizontal.ant-divider-with-text::after {
  border-top: 1px solid #b6992d;
}
.summer > .ant-divider-inner-text {
  color: #b6992d;
  background-color: #f1ce63;
}

.autumn.ant-divider-horizontal.ant-divider-with-text::before,
.autumn.ant-divider-horizontal.ant-divider-with-text::after {
  border-top: 1px solid #f28e2b;
}
.autumn > .ant-divider-inner-text {
  color: #f28e2b;
  background-color: #ffbe7d;
}

.thumb {
  width: 90vmin;
  height: calc(90vmin * 9 / 16);
  margin: 70px auto;
  perspective: 1000px;
}

.thumb div {
  display: block;
  width: 100%;
  height: 100%;
  background-size: 0, cover;
  transform-style: preserve-3d;
  transition: all 0.5s;
}

.thumb:hover div {
  transform: rotateX(80deg);
  transform-origin: bottom;
}
.thumb div:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 36px;
  background: inherit;
  background-size: cover, cover;
  background-position: bottom;
  transform: rotateX(90deg);
  transform-origin: bottom;
}
.thumb div span {
  color: white;
  text-transform: uppercase;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  font: bold 12px/36px "Open Sans";
  text-align: center;
  transform: rotateX(-89.99deg);
  transform-origin: top;
  z-index: 1;
}
.thumb div:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 100px 50px rgba(0, 0, 0, 0.5);
  transition: all 0.5s;
  opacity: 0.15;
  transform: rotateX(95deg) translateZ(-80px) scale(0.75);
  transform-origin: bottom;
}

.thumb:hover div:before {
  opacity: 1;
  box-shadow: 0 0 25px 25px rgba(0, 0, 0, 0.5);
  transform: rotateX(0) translateZ(-60px) scale(0.85);
}

.box-out {
  z-index: -99;
  width: 720px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 140px;
}

.box-inner {
  /* display: none; */
  z-index: 99;
  width: 900px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  position: absolute;
  top: 350px;
  overflow: hidden;
  background-color: #fff;
}

.book {
  width: 180px;
  height: 255px;
  /*background-color: rgb(62, 71, 152);*/
  transition: all 0.3s ease-in-out;
  transform-origin: left center 0;
  transform-style: preserve-3d;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  -webkit-backface-visibility: hidden;
  overflow: hidden;
  border: 1px solid #cac9c9;
}

.box-out .book::after {
  content: " ";
  display: block;
  opacity: 0;
  width: 180px;
  height: 255px;
  position: relative;
  left: 8px;
  transition: all 0.3s ease;
}

.box-out .book::before {
  content: " ";
  z-index: 999;
  display: block;
  width: 20px;
  height: 100px;
  opacity: 0;
  position: absolute;
  top: -12px;
  right: 8px;
  transition: all 0.25s;
  background-size: 16px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD8AAABhCAYAAABh23lYAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHCSURBVHgB7dxPTsJAHMXxN7Xu3UhigobxJHACvQFwAr2BcgO8AZxE7uGiDZiQyEIW7oSOUyKbKgLS8u+9b9JNF818fum0XdWUhy9VEDZBGIdBGD6DsFM3bQYgTnjWhGdNeNaEZ0141oRnTXjWhGdNeNaEZ0141oRnTXjWhGdNeNaEZ0141oRnTXjWhGdNeNaEZ0141oRnTXjWhGdNeNaEZ0141oRnTXjWhGdNeNaEZ0141oRnTXjWhGdNeNZ2hR/7o+UcmnCIsaNCbLcU/RR8oB1bO/4+17l8ixoGeIBBBVvMXI0ih+L7Df2j8iiqnvgh+AVVUXDOTZvF4tNb2qC7DJ1tNgSDO78tblFQxeE92l+0NSjZDjaoMowqyYl7hDF15JzL+x+YHtxLgFq/ZO2m8LT4wsb90nUjmMD61XaRc7ng5+jBua29ntsecq6oIWyELxqdLe8h/GvPp+h0T28D/FezZ8Ip7pHgZt3X5NoPvH1BZ5sNIUTDL7C+6hBWxxt0gk+00tsOe96qH0zL8GO4pBtMg/YhoLMtG8Ii/EpfY4fSoiFk8UeFzpYdwhz/jiNGZ0uHEBjUEzftohJFZyAsdX8BM0/amLC3spYAAAAASUVORK5CYII=")
    no-repeat;
}

.box-inner .book {
  margin-left: 90px;
  position: relative;
  top: -194px;
}

/* ----- hover ----- */

.book:hover {
  cursor: pointer;
  transform: rotateY(-28deg) rotateZ(-2deg) scale(1.02);
  -webkit-backface-visibility: hidden;
  box-shadow: 1px 3px 2px #353d85, 20px 8px 0 #525dc4;
  /* transform: scale(1.02); */
}

.book:hover::after {
  content: " ";
  display: block;
  opacity: 1;
  width: 172px;
  height: 255px;
  position: relative;
  left: 8px;
  background: linear-gradient(
    -180deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0) 60%
  );
}

.book:hover::before {
  transform: translateY(9px);
  opacity: 1;
}

.dr {
  position: absolute;
  bottom: 16px;
  right: 16px;
  width: 100px;
}

.headline3 {
  position: relative;
  margin-left: -22px; /* 15px padding + 7px border ribbon shadow*/
  margin-right: -22px;
  padding: 15px;
  background: #e5e5e5;
  background: linear-gradient(#f5f5f5, #e5e5e5);
  box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.8) inset;
  text-shadow: 0 1px 0 #fff;
}

.headline3:before,
.headline3:after {
  position: absolute;
  left: 0;
  bottom: -6px;
  content: "";
  border-top: 6px solid #555;
  border-left: 6px solid transparent;
}

.headline3:before {
  border-top: 6px solid #555;
  border-right: 6px solid transparent;
  border-left: none;
  left: auto;
  right: 0;
  bottom: -6px;
}

.sticker {
  --c1: #273998;
  --c2: #265433;
  --c3: #2a4288;
  --c4: #40bf9d;
  --c5: #1aa50a;
  --shine-angle: 15deg;
  display: inline-grid;
  grid-template-areas: "text";
  place-items: center;
  font-family: "Alegreya Sans SC", sans-serif;
  font-weight: 900;
  font-style: italic;
  font-size: 1em;
  text-transform: uppercase;
  color: var(--c5);
  text-align: center;
}

.sticker-lg {
  font-size: clamp(6rem, 30vw, 20rem);
}

.sticker span {
  background: linear-gradient(
      var(--shine-angle),
      rgba(255, 0, 0, 0) 0%,
      rgba(255, 0, 0, 0)
    ),
    linear-gradient(to right, var(--c1), var(--c5));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0.01em rgba(0, 0, 0, 0.6);
}

.sticker > *,
.sticker::before,
.sticker::after {
  grid-area: text;
}

.sticker::before,
.sticker::after {
  content: attr(data-text);
  color: #fff;
}

.sticker::before {
  -webkit-text-stroke: 0.21em white;
  /*background: no-repeat linear-gradient(white, white) 15% 50% / 85% 60%;*/
}

.sticker::after {
  text-shadow: 0.07em 0.08em 0.05em rgba(0, 0, 0, 0.75),
    -0.07em -0.05em 0.05em rgba(0, 0, 0, 0.75);
  z-index: -2;
}

.ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.camelCase {
  text-transform: lowercase;
}

.camelCase:first-letter {
  text-transform: uppercase;
}

.upperCase {
  text-transform: uppercase;
}

.RecipeTableRow {
  cursor: pointer;
}
